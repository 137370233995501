.CalendarDay__selected, 
.CalendarDay__selected:active, 
.CalendarDay__selected:hover {
    border: 0px solid #FFF;
}

.DateRangePicker_picker{
  z-index: 3;
}

.DateInput_input{
  padding:7px 0px 3px 0px;
  border-bottom: 1px solid grey;
  color:grey;
  font-size:16px

}

.DateInput{
  margin-right:10px;
}
  .CalendarDay__selected {
    background: orange;
    color: black;
    border-radius: 30px;
  }
  
  .CalendarDay__default {
    border: 0px solid #FFF;
  }