.CalendarDay__selected, 
.CalendarDay__selected:active, 
.CalendarDay__selected:hover {
    border: 0px solid #FFF;
}
  
  
  .CalendarDay__selected {
    background: orange;
    color: black;
    border-radius: 30px;
  }
  
  .CalendarDay__default {
    border: 0px solid #FFF;
  }