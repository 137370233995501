.cell-box{
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    white-space:nowrap
}

#roster-print{
  display: none;
}